<template>
  <div class="column is-12">
    <p class="has-text-grey-light has-text-centered">
      &copy; {{ $moment().format("YYYY") }} {{ hostname }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Copyright",
  computed: {
    hostname() {
      return window.location.hostname;
    }
  }
};
</script>
